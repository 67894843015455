import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var enGB = {
  mddb: {
    routes: {
      title: 'MDDB',
      desc: 'Data of the <span class="vis-nowrap">ZEISS Vision Care</span> machine park and the administration of this data.',
      createMaster: {
        desc: 'Create Master',
        title: 'Create Master'
      },
      createObject: {
        desc: 'Create Object',
        title: 'Create Object'
      },
      detailObject: {
        desc: 'Edit Object',
        title: 'View Object'
      },
      editMaster: {
        desc: 'Maintain Masters',
        title: 'Edit Master'
      },
      editObject: {
        desc: 'Edit Object',
        title: 'Edit Object'
      },
      maintenance: {
        desc: '',
        title: 'Maintenance'
      },
      master: {
        desc: 'Maintain Masters',
        title: 'Master'
      },
      meta: {
        desc: 'Maintain Metadata',
        title: 'Metadata'
      },
      object: {
        desc: 'Maintain Objects',
        title: 'Object'
      },
      operatingData: {
        desc: 'Maintain Operating Data',
        title: 'Operating Data'
      },
      pcp: {
        desc: '',
        title: 'PCP'
      },
      qrCode: {
        desc: '',
        title: 'QR-Code'
      },
      qrCodeScan: {
        desc: 'Scan MDDB QR-Codes directly from the Vision Portal',
        title: 'QR-Code Scanner'
      },
      viewMaster: {
        desc: 'Maintain Masters',
        title: 'View Master'
      },
      viewObject: {
        desc: 'Edit Object',
        title: 'View Object'
      },
      wiki: {
        desc: 'Get Started with MDDB',
        title: 'Wiki'
      },
      print: {
        desc: 'Print labels for multiple objects',
        title: 'Bulk printing'
      }
    }
  }
};
var esES = {
  mddb: {
    routes: {
      title: 'MDDB',
      desc: 'Fecha del parque de máquinas de <span class="vis-nowrap">ZEISS Vision Care</span> y administración de estos datos.',
      createMaster: {
        desc: 'Crear maestros.',
        title: 'Crear maestro'
      },
      createObject: {
        desc: 'Crear objeto.',
        title: 'Crear objeto'
      },
      detailObject: {
        desc: 'Editar objeto.',
        title: 'Ver objeto'
      },
      editMaster: {
        desc: 'Mantener maestros.',
        title: 'Editar maestro'
      },
      editObject: {
        desc: 'Editar objeto.',
        title: 'Editar objeto'
      },
      maintenance: {
        desc: '',
        title: 'Mantenimiento'
      },
      master: {
        desc: 'Mantener maestros.',
        title: 'Maestro'
      },
      meta: {
        desc: 'Mantener metadatos.',
        title: 'Metadatos'
      },
      object: {
        desc: 'Mantener objetos.',
        title: 'Objeto'
      },
      operatingData: {
        desc: 'Mantener datos operativos.',
        title: 'Datos operativos'
      },
      pcp: {
        desc: '',
        title: 'PCP'
      },
      qrCode: {
        desc: '',
        title: 'Código QR'
      },
      viewMaster: {
        desc: 'Mantener maestros.',
        title: 'Ver maestro'
      },
      viewObject: {
        desc: 'Editar objeto.',
        title: 'Ver objeto'
      },
      wiki: {
        desc: 'Introducción a MDDB',
        title: 'Wiki'
      }
    }
  }
};
var deDE = {
  mddb: {
    routes: {}
  }
};

// Translations which can be used in VP outside library. Please do not export everything, this is exception, not a rule!
const MDDB_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGB
  },
  [SystemLanguage['es-ES']]: {
    ...esES
  },
  [SystemLanguage['de-DE']]: {
    ...deDE
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { MDDB_TRANSLATIONS };
